import React from "react";

// Styles
import "../components/Blog/PostContent/styles.css";

const LegalNotice = (): JSX.Element => (
  <article className="prose prose-lg lg:prose-xl max-w-3xl mx-auto text-body">
    <h1 style={{ textAlign: "center" }}>Mentions légales</h1>

    <p>
      <i>En vigueur au 20/01/2021</i>
      <br />
      Conformément aux dispositions des Articles 6-III et 19 de la Loi
      n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
      dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site
      ekee.io les présentes mentions légales.
    </p>
    <p>
      La connexion et la navigation sur le site (indiquer le nom du site) par
      l’Utilisateur implique acceptation intégrale et sans réserve des présentes
      mentions légales.
      <br />
      Ces dernières sont accessibles sur le site à la rubrique « Mentions
      légales ».
    </p>
    <h2>ARTICLE 1 : L’éditeur</h2>
    <p>
      L'édition du site ekee.io est assurée par la Société SAS eKee au capital
      de 470.000 euros, immatriculée au RCS de BORDEAUX sous le numéro 847622818
      dont le siège social est situé au 12 cours de l'Intendance, numéro de
      téléphone 06.16.67.84.23, adresse e-mail : contact@ekee.io.
      <br />
      N° de TVA intracommunautaire : FR80847622818
      <br />
      Le Directeur de la publication est Philippe BERNARD
      <br />
    </p>
    <h2>ARTICLE 2 : L’hébergeur</h2>
    <p>
      L'hébergeur du site ekee.io est la Société VERCEL, dont le siège social
      est situé au 340 S Lemon Ave #4133, Walnut CA, 91789 – USA,
      contact@vercel.com .
    </p>
    <h2>ARTICLE 3 : Accès au site</h2>
    <p>
      Le site est accessible par tout endroit, 7j/7, 24h/24 sauf cas de force
      majeure, interruption programmée ou non et pouvant découlant d’une
      nécessité de maintenance.
      <br />
      En cas de modification, interruption ou suspension des services le site
      ekee.io ne saurait être tenu responsable.
    </p>
    <h2>ARTICLE 4 : Collecte des données</h2>
    <p>
      Le site est exempté de déclaration à la Commission Nationale Informatique
      et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée
      concernant les utilisateurs.
    </p>
    <h2>ARTICLE 5 : Cookies</h2>
    <p>
      L’Utilisateur est informé que lors de ses visites sur le site, un cookie
      peut s’installer automatiquement sur son logiciel de navigation. En
      naviguant sur le site, il les accepte.
    </p>
    <p>
      Un cookie est un élément qui ne permet pas d’identifier l’Utilisateur mais
      sert à enregistrer des informations relatives à la navigation de celui-ci
      sur le site Internet. L’Utilisateur pourra désactiver ce cookie par
      l’intermédiaire des paramètres figurant au sein de son logiciel de
      navigation.
    </p>
    <h2>ARTICLE 6 : Propriété intellectuelle</h2>
    <p>
      Toute utilisation, reproduction, diffusion, commercialisation,
      modification de toute ou partie du site <i>ekee.io</i>, sans autorisation
      de l’Editeur est prohibée et pourra entraînée des actions et poursuites
      judiciaires telles que notamment prévues par le Code de la propriété
      intellectuelle et le Code civil.
    </p>
    <br />
    <br />
    <br />
  </article>
);

export default LegalNotice;
